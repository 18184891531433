@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import "react-alice-carousel/lib/alice-carousel.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

ul, 
li {
    list-style: revert;
}

.slick-slide > div {
  margin: 0 10px;
}
